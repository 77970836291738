import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Button from '../components/button';
import Album, {AlbumPreviewContainer} from '../components/album';
import TextCard from '../components/textCard';
import Link from '../components/betterLink';
import SEO from '../components/seo';
import HtmlReactParser from 'html-react-parser';
import ReactToString from '../helper/reactToString';

const QuoteButton = styled(Button)`
    display: block;
    margin: 0 auto 90px auto;
`;

const TextCardWrapper = styled.div`
    padding: 120px 0 30px 0;
`;

const AlbumWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 40px 90px 40px;
`;

export const ServiceTemplate = ({
    preview,
    title,
    nameSplitPosition,
    description,
    gallery,
    thumbnail,
    url,
})=>(
    <div>
        {
            !preview 
            && 
            <SEO 
                title={title} 
                description={ReactToString(description)}
                image={url+thumbnail.childImageSharp.fluid.src}
            />
        }
        <TextCardWrapper>
            <TextCard title={title} nameSplitPosition={nameSplitPosition} description={description} />
        </TextCardWrapper>
        {
            preview ?
                <QuoteButton text="諮詢估價" />
            :
                <Link to="/contact">
                    <QuoteButton text="諮詢估價" />
                </Link>
        }
        {
            preview ? 
                <AlbumWrapper>
                    {gallery._tail.array.map((name,index)=>{
                        return(<AlbumPreviewContainer key={index} name={name}/>)
                    })}
                </AlbumWrapper>
            :
                <AlbumWrapper>
                    {gallery.map(({title,fluids},index)=>{
                        return(<Album key={index} title={title} fluids={fluids} />)
                    })}
                </AlbumWrapper>
        }
    </div>
);

const Service = ({data})=>{ 
    const { 
        title: serviceTitle,
        nameSplitPosition: serviceNameSplitPosition,
        gallery: serviceGallery,
        thumbnail: serviceThumbnail,
    } = data.markdownRemark.frontmatter;
    const serviceDescription = HtmlReactParser(data.markdownRemark.html);
    const {url} = data.site.siteMetadata;
    const requestTitles = serviceGallery;
    const allAlbums = data.allAlbums.edges;
    let selectedAlbums = [];
    requestTitles.forEach(requestTitle=>{
        allAlbums.forEach(({node: album})=>{
            requestTitle === album.frontmatter.title &&
            selectedAlbums.push(
                {
                    title: requestTitle, 
                    fluids: album.frontmatter.content.map(c=>c.childImageSharp.fluid)
                }
            );
        })
    })
    return(
        <ServiceTemplate
            title={serviceTitle}
            nameSplitPosition={serviceNameSplitPosition}
            description={serviceDescription}
            gallery={selectedAlbums}
            thumbnail={serviceThumbnail}
            url={url}
        />
    )
};

export default Service;

export const query = graphql`
    query postByID($id : String!){
        site {
            siteMetadata {
                url
            }
        }
        markdownRemark(id: { eq: $id }){
            html
            frontmatter{
                title
                nameSplitPosition
                gallery
                thumbnail{
                    childImageSharp{
                        fluid(maxWidth: 400){
                            src
                        }
                    }
                }
            }
        }
        allAlbums : allMarkdownRemark(filter:{ frontmatter:{ type:{ eq: "album"}}}){
            edges{
                node{
                    frontmatter{
                        title
                        content{
                            childImageSharp{
                                fluid(maxWidth: 2048){
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }   
        }
    }
`;