import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${breakpoint('tablet')`
        flex-direction: row;
        align-items: flex-start;
    `}
    justify-content: center;
    max-width: 900px;
    margin: auto;
`;

const Title = styled.div`
    ${breakpoint('tablet')`
        align-self: start;
        min-width: 360px;
        width: 360px;
    `}
    text-align: center;
    min-width: 280;
    width: 280;
    font-size: ${({length})=> {
        if(!length || length<= 5){
            return "65px";
        }else if(length > 5){
            return "45px";
        }else if(length > 8){
            return "35px";
        }
    }};
`;

const Underline = styled.div`
    display: inline-block;
    border-bottom: 2px solid ${({theme})=> theme && theme.color ? `${theme.color.text.secondary}`: `#3f3f3f`};
`;

const Description = styled.div`
    flex: 1;
    text-align: left;
    box-sizeing: border-box;
    padding: 25px;
    ${breakpoint('tablet')`
        padding: 35px;
    `}
`;

const TextCard = ({title, nameSplitPosition, description})=>{
    const main = title.slice(0,nameSplitPosition);
    const sub = title.slice(nameSplitPosition);
    return(
        <Wrapper>
            <Underline>
                {
                    !nameSplitPosition && 
                    <Title length={title.length}>
                        {title}
                    </Title>
                }
                {nameSplitPosition&&main&&
                    <Title length={main.length}>
                        {main}
                    </Title>
                }
                {nameSplitPosition&&sub&&
                    <Title length={sub.length}>
                    {sub}
                    </Title>
                }
            </Underline>
            <Description>
                {description}
            </Description>
        </Wrapper>
    )
};

export default TextCard;